import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import {
    IWebPageURL,
    IImageURL,
    isoWebPageURL,
} from "tsi-common-react/src/models/nominals";
import {
    IProduct,
    IConcreteBundle,
} from "tsi-common-react/src/models/catalogue.interfaces";
import format from "tsi-common-react/src/utils/format";
import { Image } from "tsi-common-react/src/common/Image";

interface IProps {
    isBundle: boolean;
    bundle: IConcreteBundle;
    imagePath: IImageURL;
    imageAlt: string;
    ctaPath?: IWebPageURL | null;
    ctaCopy?: string;
    buyIsDisabled: boolean;
    onAddToBasket: (product: IProduct) => void;
}

// TODO: Determine if this is a safe assumption re: targeting the appropriate suggested product
// i.e. Is there a use case where there would be more than one suggested product in a bundle?
export function UpsellModalSlide(props: IProps) {
    function onAddToBasket(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        props.onAddToBasket(props.bundle.suggested_products[0]);
    }

    function buildPrice(priceExcTax: string, priceRetail: string) {
        if (!priceRetail || priceRetail === priceExcTax) {
            return (
                <div className="upsell-modal-slide__price">
                    {format.money(priceExcTax)}
                </div>
            );
        }
        return (
            <div className="upsell-modal-slide__price">
                <span className="ada-screenreader-only">
                    {t`Original price:`}
                </span>
                <span className="upsell-modal-slide__price--strike">
                    {format.money(priceRetail)}
                </span>
                <span className="ada-screenreader-only">
                    {t`Current price:`}
                </span>
                <span className="upsell-modal-slide__price--highlight">
                    {format.money(priceExcTax)}
                </span>
            </div>
        );
    }

    function renderTitle() {
        if (!props.isBundle) {
            return null;
        }
        const product = props.bundle.suggested_products[0];
        if (!product || !product.price.cosmetic_excl_tax) {
            return null;
        }
        const productTitle = product.parent
            ? product.parent.title
            : product.title;
        return (
            <div className="upsell-modal-slide__title">
                {buildPrice(
                    product.price.cosmetic_excl_tax,
                    product.price.retail || "",
                )}
                <div className="upsell-modal-slide__product-title">
                    {productTitle}
                </div>
            </div>
        );
    }

    function renderCTA() {
        if (props.isBundle) {
            let addToBasketButtonText: string = t`Add to Cart`;

            const product = props.bundle.suggested_products[0];
            if (
                product &&
                product.attributes &&
                product.attributes.preorder &&
                product.attributes.preorder.value
            ) {
                addToBasketButtonText = t`Pre-order`;
            }

            return (
                <button
                    className="button upsell-modal-slide__cta upsell-modal-slide__cta--bundle al-upsell-modal__button--add-to-cart"
                    disabled={props.buyIsDisabled}
                    onClick={onAddToBasket}
                >
                    {addToBasketButtonText}
                </button>
            );
        }
        return (
            <a
                className="button button--ghost upsell-modal-slide__cta upsell-modal-slide__cta--misc"
                href={
                    props.ctaPath
                        ? isoWebPageURL.unwrap(props.ctaPath)
                        : undefined
                }
            >
                {props.ctaCopy}
            </a>
        );
    }

    const imgClasses = classNames({
        "upsell-modal-slide__image": true,
        "upsell-modal-slide__image--bundle": props.isBundle,
    });

    // TODO accessibility: remove text from images when refactoring this component
    return (
        <div
            className="upsell-modal-slide"
            role="group"
            aria-label="Power base model"
        >
            <div className="upsell-modal-slide__cta-container">
                {renderTitle()}
                {/*
                        Looks like image loading is completed after Flickty is mounted.
                        This loaded image by lazyload have no size, which throws off cell positions in the Flickity so turning off lazyload to fix this issue.
                    */}
                <Image
                    lazy={false}
                    className={imgClasses}
                    src={props.imagePath}
                    alt={props.imageAlt}
                />
                {renderCTA()}
            </div>
        </div>
    );
}

export default UpsellModalSlide;
